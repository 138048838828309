@font-face {
  font-family: 'DIN Web';
  font-style: normal;
  font-weight: 100;
  src: local("DIN WebUltraLight"), local("DINWeb-UltraLight"), url("https://s3-us-west-1.amazonaws.com/codegophers.com/fonts/DINWeb-UltraLight.woff") format("woff"); }

@font-face {
  font-family: 'DIN Web';
  font-style: normal;
  font-weight: 300;
  src: local("DIN WebLight"), local("DINWeb-Light"), url("https://s3-us-west-1.amazonaws.com/codegophers.com/fonts/DINWeb-Light.woff") format("woff"); }

@font-face {
  font-family: 'DIN Web';
  font-style: normal;
  font-weight: 400;
  src: local("DIN Web"), local("DINWeb"), url("https://s3-us-west-1.amazonaws.com/codegophers.com/fonts/DINWeb.woff") format("woff"); }

@font-face {
  font-family: 'DIN Web';
  font-style: normal;
  font-weight: 500;
  src: local("DIN WebMedium"), local("DINWeb-Medium"), url("https://s3-us-west-1.amazonaws.com/codegophers.com/fonts/DINWeb-Medium.woff") format("woff"); }

@font-face {
  font-family: 'DIN Web';
  font-style: normal;
  font-weight: 700;
  src: local("DIN WebBold"), local("DINWeb-Bold"), url("https://s3-us-west-1.amazonaws.com/codegophers.com/fonts/DINWeb-Bold.woff") format("woff"); }

@font-face {
  font-family: 'DIN Web';
  font-style: normal;
  font-weight: 800;
  src: local("DIN WebBlack"), local("DINWeb-Black"), url("https://s3-us-west-1.amazonaws.com/codegophers.com/fonts/DINWeb-Black.woff") format("woff"); }

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* font-family: 'DIN Web', sans-serif; */
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  margin-bottom: 120px;
  color: #222;
  line-height: 145%; }
  .App code {
    line-height: 120%;
    max-width: 100%; }
  .App .content {
    padding: 10px; }

.App button {
  background: #1de4ec;
  background: linear-gradient(0deg, #0a5bed 0%, #1de4ec 100%);
  border: 0;
  color: white;
  border-radius: 8px;
  font-size: 26px;
  padding: 18px 30px;
  cursor: pointer; }
  .App button:hover {
    opacity: .8; }
  .App button:active {
    opacity: .4; }
  .App button a {
    color: white;
    text-decoration: none; }
    .App button a:hover {
      color: white; }

.App .logo {
  color: white;
  text-align: center;
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #ddd; }
  .App .logo * {
    color: #282c34; }
  .App .logo .img {
    height: 40px;
    margin: 10px 0 20px 0; }
    .App .logo .img img {
      max-height: 40px; }
  .App .logo .pandas,
  .App .logo .cloud {
    font-size: 42px;
    font-weight: 500; }
  .App .logo .in-the {
    font-size: 20px;
    line-height: 36px;
    top: 2px;
    position: relative;
    opacity: .8; }

.App .drop-in {
  font-size: 24px;
  line-height: 32px;
  margin: 40px 0; }
  .App .drop-in code {
    background: #eee;
    border-radius: 4px;
    padding: 0 2px;
    max-width: 100%; }

.App .main-img {
  margin: 80px 0 40px 0;
  text-align: center; }
  .App .main-img img {
    max-height: 200px;
    max-width: 100%; }

.App .one-line {
  text-align: center; }
  @media (max-width: 767px) {
    .App .one-line {
      padding: 20px; } }
  @media only screen and (min-width: 768px) {
    .App .one-line {
      padding: 40px; } }
  .App .one-line .header {
    margin: 20px 0;
    font-size: 34px;
    line-height: 40px;
    color: #505050; }
    .App .one-line .header b {
      /* font-weight: 500; */
      color: #333; }
  .App .one-line .blurb {
    margin: 20px auto;
    max-width: 680px;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    color: #505050; }
    .App .one-line .blurb b {
      font-weight: normal; }
    .App .one-line .blurb code {
      color: #333;
      background: #eee;
      border-radius: 4px;
      padding: 0 2px; }
  .App .one-line .diff {
    margin: 80px auto;
    text-align: left;
    max-width: 560px;
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 26px;
    line-height: 28px; }
    @media (max-width: 767px) {
      .App .one-line .diff {
        font-size: 14px; } }
    .App .one-line .diff .remove {
      background-color: #ffe9ec;
      padding: 4px; }
      .App .one-line .diff .remove b {
        padding: 0 2px;
        border-radius: 3px;
        /* font-weight: normal; */
        background-color: #fba8b3; }
    .App .one-line .diff .add {
      background-color: #e1ffe9;
      padding: 4px; }
      .App .one-line .diff .add b {
        padding: 0 2px;
        border-radius: 3px;
        /* font-weight: normal; */
        background-color: #9ff2b0; }

.App .details {
  margin-bottom: 80px; }
  .App .details > * {
    flex-grow: 1;
    flex-basis: 0; }
    @media only screen and (min-width: 768px) {
      .App .details > * {
        padding: 40px; } }
  @media (max-width: 767px) {
    .App .details > * + * {
      margin-top: 40px; } }
  @media only screen and (min-width: 768px) {
    .App .details {
      display: flex; } }
  .App .details .how-it-works {
    display: flex;
    flex-direction: column; }
    .App .details .how-it-works code {
      background: #f3f3f3;
      border-radius: 4px;
      padding: 0 4px; }
    .App .details .how-it-works .blurbs {
      flex-grow: 1;
      /* display: flex; */
      /* flex-direction: column; */
      /* justify-content: space-between; */ }
    .App .details .how-it-works .blurb + .blurb {
      margin-top: 80px; }
      @media (max-width: 767px) {
        .App .details .how-it-works .blurb + .blurb {
          margin-top: 20px; } }
    .App .details .how-it-works .blurb {
      display: flex;
      align-items: center;
      line-height: 140%; }
      @media (max-width: 767px) {
        .App .details .how-it-works .blurb {
          flex-direction: column; } }
      .App .details .how-it-works .blurb img {
        display: block;
        width: 80px;
        margin-right: 20px; }
        @media (max-width: 767px) {
          .App .details .how-it-works .blurb img {
            margin: 20px 0; } }
      .App .details .how-it-works .blurb .header {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 10px; }
        @media (max-width: 767px) {
          .App .details .how-it-works .blurb .header {
            text-align: center; } }
      .App .details .how-it-works .blurb .body {
        color: #505050; }
  .App .details .how-it-works-header,
  .App .details .try-header {
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center; }
  .App .details .try-it button {
    display: block;
    width: 100%; }
  .App .details .try-it .try-it-inner {
    padding: 20px;
    background: rgba(40, 44, 52, 0.9);
    color: #ddd;
    border-radius: 8px; }
    .App .details .try-it .try-it-inner b {
      font-weight: 500;
      color: white; }
  .App .details .try-it .step-header {
    font-weight: 500;
    color: #fff;
    font-size: 160%; }
  .App .details .try-it ul {
    list-style-type: none;
    padding-left: 0; }
  .App .details .try-it li + li {
    margin-top: 80px; }
  .App .details .try-it code.snippet {
    display: block;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    margin: 20px 0;
    font-size: 14px;
    border-radius: 8px;
    padding: 20px;
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
    .App .details .try-it code.snippet .green {
      color: #9ff2b0;
      font-weight: bold; }
    .App .details .try-it code.snippet.terminal {
      /* background: #eee; */
      /* color: #333; */ }
      .App .details .try-it code.snippet.terminal::before {
        content: '$ '; }

.App .section {
  margin: 40px auto; }
  @media only screen and (min-width: 768px) {
    .App .section {
      width: 700px; } }
  .App .section .title {
    font-size: 36px;
    font-weight: 500; }
  .App .section code {
    background-color: #f3f3f3;
    padding: 0 4px;
    border-radius: 4px; }
    .App .section code.block, .App .section code.terminal {
      border-radius: 8px;
      padding: 20px;
      margin: 20px 0;
      display: block; }
    .App .section code.terminal::before {
      content: '$ '; }

.App .q-a {
  margin: 40px 0; }
  .App .q-a .q {
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0; }

.App .section-pricing table {
  margin-top: 20px;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse; }
  .App .section-pricing table tr td,
  .App .section-pricing table tr th {
    border: 1px solid #ddd;
    padding: 10px 20px;
    width: 33%; }
  .App .section-pricing table button {
    font-size: 18px;
    padding: 12px 20px;
    width: 100%; }

.App .footer {
  border-top: 1px solid #eee;
  margin-top: 40px;
  padding: 40px;
  text-align: center; }
  .App .footer img {
    max-width: 100%;
    width: 300px; }
